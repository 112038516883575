




























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AdviseModule from "@/store/modules/advise";
import AdvisorResultsRefine from "@/views/components/AdvisorResultsRefine.vue";
import {
  Question,
  Answer,
  CompareItem
} from "@dsm/dsm-storybook/src/components/advisor";

import {
  AdvisorQuestionFilter,
  AdvisorResultsItem,
  AdvisorResultsItemRd,
  AdvisorResultsItemSm,
  AdvisorTabs,
  AdvisorTab,
  AdvisorResultsMatchScoreTable,
  AdvisorResultsLinkList,
  AdvisorResultsButtonList,
  AdvisorResultsTotal,
  AdvisorIcon,
  AdvisorCompare,
  AdvisorShare,
} from "@dsm/dsm-storybook";
import { mockResultsData, refineYourSearch } from "@/service/initial-data";
import Paginate from "vuejs-paginate";
import router from "@/router";
import settings from "@/service/settings";

const advise = namespace("advise");

@Component({
  components: {
    AdvisorQuestionFilter,
    AdvisorResultsItem,
    AdvisorResultsItemRd,
    AdvisorResultsItemSm,
    AdvisorTabs,
    AdvisorTab,
    AdvisorResultsMatchScoreTable,
    AdvisorResultsLinkList,
    AdvisorResultsButtonList,
    AdvisorResultsTotal,
    AdvisorIcon,
    AdvisorCompare,
    Paginate,
    AdvisorShare,
    AdvisorResultsRefine
  }
})
export default class ResultsView extends Vue {
  @Prop({ default: '', type: String })
  readonly qAnswers!: string;

  @Prop({ default: 0, type: Number})
  readonly resultsPageCount!: number

  // @advise.Mutation("setScore")
  // setScore!: AdviseModule["setScore"];

  @advise.Mutation("setStep")
  adviceSetStep!: AdviseModule["setStep"];

  @advise.Mutation("setPage")
  paginationSetStep!: AdviseModule["setPage"];

  @advise.Mutation("updateAnswer")
  adviseUpdateAnswer!: AdviseModule["updateAnswer"];

  @advise.Mutation("skipQuestion")
  adviseSkipQuestion!: AdviseModule["skipQuestion"];

  @advise.Mutation("addCompare")
  addCompare!: AdviseModule["addCompare"];

  @advise.Mutation("removeCompare")
  removeCompare!: AdviseModule["removeCompare"];

  @advise.Mutation("resetState")
  resetState!: AdviseModule["resetState"];

  @advise.State("questions")
  questions!: AdviseModule["questions"];

  @advise.Getter("totalResults")
  totalResults!: number;

  @advise.Getter("productsResults")
  productsResults!: AdviseModule["products"];

  @advise.Getter("paginationResults")
  paginationResults!: AdviseModule["pagination"];

  @advise.Getter("userAnswers")
  userAnswers!: AdviseModule["answers"];

  @advise.Getter("compareItems")
  compareItems!: AdviseModule["compareItems"];

  @advise.Action("getResults")
  adviseGetResults!: AdviseModule["getResults"];

  currentPage = 1;

  iframeFormObj = {
    icon: "",
    title: "",
    src: ""
  };

  showTooltipResults = false;

  get mainQuestions() {
    const questions: Question[] = [];
    this.questions.forEach(question => {
      if (question.refine === false) {
        questions.push(question);
      }
    });
    return questions;
  }

  get refineQuestions() {
    const questions: Question[] = [];
    this.questions.forEach(question => {
      if (question.refine === true) {
        const answer = this.userAnswers[question.index];
        if (answer !== null) {
          questions.push(question);
        }
      }
    });
    return questions;
  }

  /**
   * Always set last step of the wizard as the active step.
   */
  created() {
    this.adviceSetStep(9);
    this.currentPage = this.paginationResults.current_page;
    this.showTooltipResults = this.resultsPageCount === 1;

    // Check for answers set via url
    this.getResultsOnQueryAnswers();
  }

  updated() {
    // Disable spinner
    this.$emit("showSpinner", false);
  }

  getResultsOnQueryAnswers() {
    if (this.qAnswers !== '') {
      // Clear current state
      this.resetState();
      this.$emit("showSpinner", true);

      // Init pagination
      this.paginationSetStep(1);
      this.currentPage = 1;

      // Split answers
      const qAnswers = this.qAnswers.split(',');
      qAnswers.forEach(qAnswer => {
        // Split question and answer
        const cAnswer = qAnswer.split('-');
        if (cAnswer.length === 2) {
          const cAnswerIndex = parseInt(cAnswer[0]);
          const cAnswerAnswer = cAnswer[1];

          // Find question object
          const question = this.questions.find(question => {
            return question.index === cAnswerIndex;
          });

          if (question !== undefined) {
            // find answer object
            const answer = question.answers.find(answer => {
              return answer.value === cAnswerAnswer;
            })

            // Append answer
            if (answer !== undefined) {
              // Update answer
              this.adviseUpdateAnswer({ question, answer });
            }
          }
        }
      });

      // Get results
      this.adviseGetResults();

      this.$router.push({
        name: "results"
      });
    }
  }

  paginationCallback(pageNumber: number) {
    // Update pagination
    this.paginationSetStep(pageNumber);
    // Get results
    this.adviseGetResults();
    // Top
    window.scroll({ top: 0, behavior: "smooth" });
    // Emit event
    this.$emit("postMessage", "resetScroll");
  }

  onFilterClick(question: Question, answer: Answer) {
    this.paginationSetStep(1);
    this.currentPage = 1;
    // Update answers
    this.adviseUpdateAnswer({ question, answer });
    // Get results
    this.adviseGetResults();
  }

  onSkip(question: Question) {
    this.paginationSetStep(1);
    this.currentPage = 1;
    // Update answers
    this.adviseSkipQuestion(question);
    // Get results
    this.adviseGetResults();
  }

  routerLinkCTA(routerName: string) {
    this.$router.push(routerName);
  }

  beforeModalOpen() {
    document.body.classList.add("modal-open");
  }

  beforeModalClose() {
    document.body.classList.remove("modal-open");
  }

  closeModal(name: string) {
    this.$modal.hide(name);
  }

  iframeLinkButtonList(formObj: any, param: string) {
    this.iframeFormObj.icon = formObj.icon;
    this.iframeFormObj.title = formObj.title;
    let url = formObj.link.substring(formObj.link.lastIndexOf(":") + 1);
    if (param !== "") {
      url += param;
    }
    this.iframeFormObj.src = url;
    this.$modal.show("iframe-form-modal");
  }

  compareItem(checked: boolean, compareItem: CompareItem) {
    if (checked) {
      this.addCompare(compareItem);
    } else {
      const compareItemIndex = this.compareItems.findIndex(
        item => item.id === compareItem.id
      );
      this.removeCompare(compareItemIndex);
    }
  }

  compareItemRemove(index: number) {
    this.removeCompare(index);
  }

  compareItemsExec() {
    let url = settings.BASE_URL;
    let paramValues = "";
    this.compareItems.forEach(item => {
      if (paramValues !== "") {
        paramValues += ",";
      }
      paramValues += item.id;
    });
    url += "/navigate?compare=" + paramValues;
    window.open(url, "_blank");
  }

  resetAndGoToStart() {
    this.resetState();
    // Get initial total of products
    this.adviseGetResults();

    this.$router.push({
      name: "welcome"
    });
  }

  resetToView() {
    this.$router.push({
      name: "welcome"
    });
  }

  toggleItem() {
    setTimeout(() => {
      this.$emit("postMessage", "calcHeight");
    }, 100);
  }

  downloadPdf() {
    this.$emit("downloadPdf");
  }
}
