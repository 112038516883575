


























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  AdvisorIcon,
  AdvisorRefineQuestion,
  Answer,
  Question
} from "@dsm/dsm-storybook";
import AdviseModule from "@/store/modules/advise";

const advise = namespace("advise");

@Component({
  components: {
    AdvisorIcon,
    AdvisorRefineQuestion
  }
})
export default class RefineView extends Vue {
  @advise.State("questions")
  questions!: AdviseModule["questions"];

  @advise.Getter("userAnswers")
  answers!: AdviseModule["answers"];

  @advise.Getter("totalResults")
  totalResults!: number;

  @advise.Mutation("updateAnswer")
  adviseUpdateAnswer!: AdviseModule["updateAnswer"];

  @advise.Mutation("skipQuestion")
  adviseSkipQuestion!: AdviseModule["skipQuestion"];

  @advise.Mutation("removedAnswer")
  adviseRemoveAnswer!: AdviseModule["removedAnswer"];

  @advise.Action("getResults")
  adviseGetResults!: AdviseModule["getResults"];

  refineAnswers = [] as any;

  show() {
    this.$modal.show("update-selection-modal");
  }

  closeModal() {
    this.$modal.hide("update-selection-modal");
  }

  get refineQuestions() {
    const questions: Question[] = [];
    this.questions.forEach(question => {
      if (question.refine === true) {
        questions.push(question);
      }
    });
    return questions;
  }

  onFilterChange(question: Question, answer: Answer) {
    if (this.refineAnswers.indexOf(question.index) === -1) {
      this.refineAnswers.push(question.index);
    }

    if (typeof answer !== "undefined") {
      this.adviseUpdateAnswer({ question, answer });
    } else {
      this.adviseRemoveAnswer(question);
    }
  }

  goBack() {
    if (this.refineAnswers.length > 0) {
      this.show();
    } else {
      this.$router.push({
        name: "results"
      });
    }
  }

  goBackWithoutUpdate() {
    this.refineAnswers.forEach((item: any) => {
      const question = this.questions[item - 1]; // 0 based
      this.adviseRemoveAnswer(question);
    });

    this.$router.push({
      name: "results"
    });
  }

  updateSelection() {
    // Get new results an goto results page
    this.adviseGetResults();
    this.$router.push({
      name: "results"
    });
  }
}
