
























import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  AdvisorIcon,
  AdvisorRefineQuestionSimple,
  Answer,
  Question
} from "@dsm/dsm-storybook";
import AdviseModule from "@/store/modules/advise";

const advise = namespace("advise");

@Component({
  components: {
    AdvisorRefineQuestionSimple,
    AdvisorIcon
  }
})
export default class AdvisorResultsRefine extends Vue {
  @advise.State("questions")
  questions!: AdviseModule["questions"];

  @advise.Getter("userAnswers")
  answers!: AdviseModule["answers"];

  @advise.Mutation("updateAnswer")
  adviseUpdateAnswer!: AdviseModule["updateAnswer"];

  @advise.Mutation("removedAnswer")
  adviseRemoveAnswer!: AdviseModule["removedAnswer"];

  @advise.Action("getResults")
  adviseGetResults!: AdviseModule["getResults"];

  showDetails = false;

  refineAnswers = [] as any;

  userAnswer = "";

  get refineQuestions() {
    const questions: Question[] = [];
    this.questions.forEach(question => {
      if (question.refine === true) {
        questions.push(question);
      }
    });
    return questions;
  }

  onFilterChange(question: Question, answer: Answer) {
    if (this.refineAnswers.indexOf(question.index) === -1) {
      this.refineAnswers.push(question.index);
    }

    if (typeof answer !== "undefined") {
      this.adviseUpdateAnswer({ question, answer });
    } else {
      this.adviseRemoveAnswer(question);
    }
  }

  updateSelection() {
    this.adviseGetResults();
  }
}
