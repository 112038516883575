












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AdvisorQuestion, Question } from "@dsm/dsm-storybook";
import { namespace } from "vuex-class";
import { Answer } from "@dsm/dsm-storybook/src/components/advisor";
import AdviseModule from "@/store/modules/advise";

const advise = namespace("advise");

@Component({
  components: {
    AdvisorQuestion
  }
})
export default class QuestionView extends Vue {
  @Prop({ default: 1, type: Number })
  readonly index!: number;

  @advise.Getter("activeQuestion")
  activeQuestion!: Question;

  @advise.Getter("userAnswers")
  userAnswers!: AdviseModule["answers"];

  @advise.Getter("lastAnsweredStep")
  lastAnsweredStep!: number;

  @advise.Mutation("setStep")
  adviseSetStep!: AdviseModule["setStep"];

  @advise.Mutation("updateAnswer")
  adviseUpdateAnswer!: AdviseModule["updateAnswer"];

  @advise.Mutation("skipQuestion")
  adviseSkipQuestion!: AdviseModule["skipQuestion"];

  @advise.Mutation("resetState")
  resetState!: AdviseModule["resetState"];

  @advise.Action("getResults")
  adviseGetResults!: AdviseModule["getResults"];

  selectedAnswer!: Answer | null | string;

  created() {
    // If the (current index - 1) of the active question is greater than last answered question
    // The state of the project is cleared and the url is still in question index > welcome state
    // Restore to welcome - question 1
    const lastAnsweredIndex = this.lastAnsweredStep;
    if ((this.index - 1) > lastAnsweredIndex) {
      this.$router.push({ name: 'welcome'});
    }
  }

  /**
   * Watch changes of prop index and update the step we are on.
   */
  @Watch("index")
  onIndexChanged() {
    this.adviseSetStep(this.index);
    this.selectedAnswer = this.userAnswers[this.index];

    if (this.index === 1) {
      const currentAnswer = this.selectedAnswer as Answer;
      if (currentAnswer && currentAnswer.value !== '') {
        this.selectedAnswer = null;
        this.resetState();
        // Get initial total of products
        this.adviseGetResults();
      }
    }

  }

  /**
   * Handle user answer to active question
   * @param answer
   */
  onAnswer(answer: Answer) {
    const question = this.activeQuestion;
    this.adviseUpdateAnswer({ question, answer });
    this.$emit("next");
  }

  /**
   *
   * Question was skipped by user
   */
  onSkip() {
    this.adviseSkipQuestion(this.activeQuestion);
    this.$emit("next");
  }

  /**
   * Make sure we update the current step after a reload when component is mounted again.
   */
  mounted() {
    this.onIndexChanged();
  }
}
